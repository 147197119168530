export const cardData = [
  {
    title: (
      <>
        Artificial Intelligence
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Dive into the world of Artificial Intelligence (AI) and explore cutting-edge technologies like machine learning, neural networks, and natural language processing. Learn how to develop AI applications and algorithms that can revolutionize industries.",
  },
  {
    title: (
      <>
        Machine Learning with Python
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Master the art of Machine Learning using Python, one of the most popular programming languages for data science and AI. Gain hands-on experience in building predictive models, analyzing data, and extracting valuable insights to drive business decisions.",
  },
  {
    title: (
      <>
        Cloud Computing
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Explore the fundamentals of Cloud Computing and learn how to leverage cloud services for storage, computing, and networking. Discover popular cloud platforms like AWS, Azure, and Google Cloud to build scalable and secure applications in the cloud.",
  },
  {
    title: (
      <>
        Ethical Hacking and
        <span className="WhatWeHave-subtitle">Cybersecurity Course</span>
      </>
    ),
    desc: "Delve into the world of Ethical Hacking and Cybersecurity to understand how to protect systems and networks from cyber threats. Learn about penetration testing, vulnerability assessment, and security best practices to safeguard digital assets.",
  },
  {
    title: (
      <>
        Data Science and Analytics
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Uncover the power of data with a Data Science and Analytics course. Explore data visualization, statistical analysis, and predictive modeling techniques to extract valuable insights from complex datasets and drive data-driven decision-making.",
  },
  {
    title: (
      <>
        Full Stack Web Development
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Embark on a journey to become a Full Stack Web Developer by mastering both front-end and back-end technologies. Learn HTML, CSS, JavaScript, Node.js, React, and more to build dynamic and responsive web applications from scratch.",
  },
  {
    title: (
      <>
        Mobile App Development
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Learn how to develop mobile applications for iOS and Android platforms with a Mobile App Development course. Explore mobile app design, user experience, and programming languages like Swift and Kotlin to create engaging and functional mobile apps.",
  },
  {
    title: (
      <>
        Cybersecurity Certification
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Earn a Cybersecurity Certification to validate your expertise in protecting digital assets and combating cyber threats. Gain in-depth knowledge of network security, cryptography, incident response, and ethical hacking to secure organizations against cyber-attacks.",
  },
  {
    title: (
      <>
        Blockchain Technology
        <span className="WhatWeHave-subtitle">Course</span>
      </>
    ),
    desc: "Discover the revolutionary potential of Blockchain Technology and learn how to build decentralized applications and smart contracts. Explore blockchain platforms like Ethereum and Hyperledger to understand the principles behind cryptocurrencies and secure transactions",
  },
];
