import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import http from "../../libs/http";

// Default State
const initialState = {
  isLoading: false,
  allBlogs: [],
  blog: null,
};

// Redux Actions
export const getBlogs = createAsyncThunk(
  "application/getBlogs",
  async (payload, thunkAPI) => {
    try {
      const response = await http.get(`/v1/blog/${payload}`);
      console.log("d", response?.data?.data);
      return thunkAPI.fulfillWithValue(response?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const postBlogs = createAsyncThunk(
  "application/getBlogs",
  async (payload, thunkAPI) => {
    try {
      const response = await http.post(`/v1/blog`, payload);

      return thunkAPI.fulfillWithValue(response?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const getAllBlogs = createAsyncThunk(
  "application/getAllBlogs",
  async (_, thunkAPI) => {
    try {
      const response = await http.get(`/v1/blogs`);
      console.log("response", response?.data?.data);
      return thunkAPI.fulfillWithValue(response?.data?.data);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
// Redux Slice
const blogSlice = createSlice({
  name: "blogSlice",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // check user auth
      .addCase(getAllBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBlogs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allBlogs = payload;
        console.log("blogs", state.allBlogs);
      })
      .addCase(getAllBlogs.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getBlogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBlogs.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.blog = payload;
        console.log("blogs", state.blog);
      })
      .addCase(getBlogs.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default blogSlice.reducer;
