import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Blogs.css";
import InfoHeader from "../../../../components/info-header-reusable/InfoHeader";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import LongArrow from "../../../../assets/images/homePage/Long Arrow.svg";
import BlogImg1 from "../../../../assets/images/homePage/blogImg1.png";
import BlogImg2 from "../../../../assets/images/homePage/blogImg2.png";
import BlogImg3 from "../../../../assets/images/homePage/blogImg3.jpg";
import BlogImg4 from "../../../../assets/images/homePage/blogImg4.png";
import { Link } from "react-router-dom";
import { scrollToBlog, scrollToTop } from "../../../../utils/scrollTo";
import { getAllBlogs } from "../../../../redux/slices/blogSlice";

const Blogs = () => {
  const headingData = {
    title: "Blogs",
    heading: "We want to know so we learn",
    description:
      "As a good digital agency Double Square also always share new things that we learn with you from our blogs.",
  };

  const dataContent = [
    {
      image: BlogImg1,
      blogTitle: "How to Leverage AI for Career Growth: Your Guide to Success",
      blogDesc:
        "it's time to streamline your hiring process and find the perfect candidates more efficiently.",
      link: "/blog/leverage-ai-for-career-growth",
    },
    {
      image: BlogImg1,
      blogTitle:
        "Secure Your Future by Mastering These 10 Most In-Demand Tech Skills",
      blogDesc:
        "There stands to be one constant in the field of technology and innovation, and that is its ever-changing landscape.",
      link: "/blog/master-these-in-demand-tech-skills",
    },
    {
      image: BlogImg1,
      blogTitle:
        "Secure Your Future by Mastering These 10 Most In-Demand Tech Skills",
      blogDesc:
        "It's time to streamline your Learning process and find the perfect Courses more efficiently.",
      link: "",
    },
  ];

  const dispatch = useDispatch();

  const allBlogs = useSelector((state) => state?.blogReducer?.allBlogs);
  console.log("hi", allBlogs);
  React.useEffect(() => {
    dispatch(getAllBlogs());
  }, [dispatch]);

  return (
    <div className="blogs-container">
      <InfoHeader
        title={headingData.title}
        heading={headingData.heading}
        description={headingData.description}
      />
      <div className="blogs-showcase-container">
        {allBlogs.map((data, idx) => (
          <div className="blogs-showcase-div" key={data?.id}>
            <div className="main-blog-post-div">
              <div className="main-post-image">
                <img src={data?.imageUrl} alt="blog thumbnail" />
              </div>
              <p>{data?.title}</p>
              <p>{data?.smallDescription}</p>
              <Link
                to={`/blog/${data?.id}`}
                className="blog-readmore-btn-style"
                onClick={() => {
                  scrollToBlog();
                  scrollToTop();
                }}
              >
                <p>Read More</p>
                {/* <div className="readmore-btn-dash" /> */}
                <img src={LongArrow} alt="arrow" className="arrow-icon" />
                {/* <MdOutlineArrowForwardIos fill="#3FA0EF" size="2rem" /> */}
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Blogs;
