import React from "react";
import hyreworksLogo from "../../assets/images/homePage/footer-logo.png";
import "./css/footer.css";
import LetUsTalkCard from "./LetUsTalkCard";
import ContactForm from "../contactForm/ContactForm";
import {
  scrollToAboutUs,
  scrollToService,
  scrollToTop,
  scrollToPricing,
  scrollToContactForm,
} from "../../utils/scrollTo";

const Footer = () => {
  const year = new Date().getFullYear();

  const openInNewTab = (url) => {
    window.open(url);
  };

  return (
    <>
      <div className="footer-main">
        <div className="footer-main-content">
          <ContactForm />
          {/* <div className="footer-navigation-bar">
            <img
              className="footer-hyreworks-logo"
              src={hyreworksLogo}
              alt="Hyreworks Logo"
              onClick={() => scrollToTop()}
            />
            <div className="footer-links">
              <div className="footer-home-link" onClick={scrollToAboutUs}>
                Home
              </div>
              <div
                className="footer-careers-link"
                onClick={() =>
                  openInNewTab(
                    "https://digimantralabs.zohorecruit.com/jobs/Careers"
                  )
                }
              >
                Careers
              </div>
              <div className="footer-services-link" onClick={scrollToService}>
                Services
              </div>
              <div className="footer-pricing-link" onClick={scrollToPricing}>
                Pricing
              </div>
              <div
                className="footer-blogs-link"
                onClick={() => openInNewTab("https://digimantralabs.com/blog/")}
              >
                Blogs
              </div>
              <div
                className="footer-contactus-link"
                onClick={scrollToContactForm}
              >
                Contact Us
              </div>
            </div>
          </div> */}

          {/* <div className="footer-border"></div> */}

          <div className="footer-bottom-form-wrapper">
            <div className="footer-text">
              <div className="footer-madewith">
                Copyright &copy; 2024. DM Learning All rights reserved.
                {/* <span className="footer-rights"></span>
                <div className="footer-rights-responsive">
                  All Rights Reserved
                </div> */}
              </div>
              <div className="footer-privacy-policy">
                <a href="/privacy-policy">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
