import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./serviceCarousel.css";
import { ServiceCarouselData } from "./serviceCarouselData";
// import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { serviceCarouselSettings } from "../../../../utils/serviceCarouselSettings";
SwiperCore.use([Navigation]);

const ServiceCarousel = () => {
  // const [progressVal, setProgressVal] = useState(1.5);
  // const serviceSliderRef = useRef();
  const swiperRef = useRef();
  // const sliderChangeCallback = (currentSlide, nextSlide) => {
  //   let calc = (nextSlide / (ServiceCarouselData.length - 1)) * 100;
  //   setProgressVal(calc);
  // };

  return (
    <div className="service-carousel-main">
      <div className="service-carousel-heading">
        <div className="service-carousel-title">
          Top <span className="primary-color-text">In-Demand</span> Tech
          <span className="service-subtitle">Skills to Learn</span>
        </div>
        <div className="service-carousel-actions">
          <span onClick={() => swiperRef.current?.slidePrev()}>
            <div className="left-btn">
              <FaChevronLeft size={20} />
            </div>
          </span>
          <span onClick={() => swiperRef.current?.slideNext()}>
            <div className="right-btn">
              <FaChevronRight size={20} />
            </div>
          </span>
        </div>
      </div>
      <div className="service-carousel-container">
        {/* <Slider
          ref={serviceSliderRef}
          beforeChange={sliderChangeCallback}
          {...serviceCarouselSettings}
          className="service-carousel-slider"
        >
          {ServiceCarouselData.map((item, key) => {
            return (
              <div className="individual-item" key={key}>
                <img
                  className="service-carousel-icon"
                  src={item.icon}
                  alt="service-category-icons"
                />
              </div>
            );
          })}
        </Slider> */}
        <Swiper
          loop={true}
          slidesPerView={4}
          spaceBetween={20}
          autoplay={{ delay: 200, pauseOnMouseEnter: true }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          className="slider-container"
        >
          {ServiceCarouselData.map((item, index) => {
            return (
              <SwiperSlide className="individual-item"  key={index}>
                <img
                  className="service-carousel-icon"
                  src={item.icon}
                  alt="service-category-icons"
                />
                {/* {item.text} */}
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* progress bar of slider */}
        <div className="service-slider-progressbar">
          <div
            className="service-slider-progressDot"
            // style={{ left: progressVal + "%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceCarousel;
