import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/home/Home";
import BlogPage from "../pages/blogs";
import PrivacyPolicy from "../pages/privacyPolicy";

const index = ({ setShowFloatForm, setFloatFormData }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          exact
          element={
            <Home
              setShowFloatForm={setShowFloatForm}
              setFloatFormData={setFloatFormData}
            />
          }
        />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route
          path="/blogs/leverage-ai-for-career-growth"
          exact
          element={<BlogPage />}
        /> */}
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
      </Routes>
    </>
  );
};

export default index;
