import { useState, useRef, useEffect } from "react";
import { Provider } from 'react-redux';
import "./App.css";
import Routes from "./routes";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import FloatingForm from "./components/floatingForm/FloatingForm";
import ClickAwayListener from "react-click-away-listener";

function App() {
  const pageRef = useRef();
  const [showFloatForm, setShowFloatForm] = useState(false);
  const [floatFormData, setFloatFormData] = useState("Let's Connect with our Sales Team");

  const [navbar, setNavbar] = useState(false);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  const observerFunc = (entries) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) {
        setNavbar(true);
      } else {
        setNavbar(false);
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(observerFunc, options);
    if (pageRef.current) observer.observe(pageRef.current);
    return () => {
      if (pageRef.current) observer.unobserve(pageRef.current);
    };
  }, [pageRef, options]);

  return (
    <div className="App">
      <Navbar
        navbar={navbar}
        setShowFloatForm={setShowFloatForm}
        setFloatFormData={setFloatFormData}
      />
      {/* <div ref={pageRef} /> */}
      <Routes
        setShowFloatForm={setShowFloatForm}
        setFloatFormData={setFloatFormData}
      />
      <Footer />
      {showFloatForm ? (
        <ClickAwayListener onClickAway={() => setShowFloatForm(false)}>
          <div className="floatinForm-wrapper">
            <FloatingForm
              setShowFloatForm={setShowFloatForm}
              floatFormData={floatFormData}
            />
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
}

export default App;

//"Let's Connect with our Design Representative"
