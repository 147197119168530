import React from "react";
import "./workDisplay.css";

import icon1 from "../../../../assets/images/homePage/icon-1.svg";
import icon2 from "../../../../assets/images/homePage/icon-2.svg";
import icon3 from "../../../../assets/images/homePage/icon-3.svg";

import workImage1 from "../../../../assets/images/homePage/workImage1.png";
import workImage2 from "../../../../assets/images/homePage/workImage2.png";
import workImage3 from "../../../../assets/images/homePage/workImage3.png";

const WorkDisplay = () => {
  return (
    <div className="work-display-main">
      <div className="work-display-divs work-display-textarea-up">
        <div className="work-display-divs work-display-textarea-up">
          <div className="work-display-title">
            Level Up with DigiMantra Learning
          </div>
          <div className="work-display-desc">
            Join more than 500+ students who upskilled themselves and landed
            their dream jobs.
          </div>
        </div>
        <div className="work-display-divs work-display-textarea-down">
          <div className="work-icon-and-text">
            <img className="icon icon-1" src={icon1} alt="icon" />
            <div className="textarea-icon-text">Try code samples</div>
          </div>
          <div className="work-icon-and-text">
            <img className="icon icon-1" src={icon2} alt="icon" />
            <div className="textarea-icon-text">Ask questions and get help</div>
          </div>
          <div className="work-icon-and-text">
            <img className="icon icon-1" src={icon3} alt="icon" />
            <div className="textarea-icon-text">
              Find technical documentation
            </div>
          </div>
        </div>
      </div>
      <div className="work-display-divs work-image-section">
        <div className="work-image-col">
          <div>
            <img
              className="image-stretch work-dsiplay-image"
              src={workImage1}
              alt="work-display-image"
            />
          </div>
          <div>
            <img
              className="work-dsiplay-image"
              src={workImage2}
              alt="work-display-image"
            />
          </div>
        </div>
        <div className="work-image-col">
          <img
            className="work-dsiplay-image"
            src={workImage3}
            alt="work-display-image"
          />
        </div>
      </div>
    </div>
  );
};

export default WorkDisplay;
