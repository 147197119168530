import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { HiMenuAlt2 } from "react-icons/hi";
import { MdOutlineClose } from "react-icons/md";
import hyreWorksLogo from "../../assets/images/homePage/dmLearningLogo.png";
import {
  scrollToAboutUs,
  scrollToService,
  scrollToPricing,
  scrollToContactForm,
  scrollToTop,
} from "../../utils/scrollTo";

function Navbar({ navbar, setShowFloatForm, setFloatFormData }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", showButton);
    return () => {
      window.removeEventListener("resize", showButton);
    };
  }, []);

  return (
    <>
      <nav className={navbar ? "navbar active" : "navbar"}>
        <div className="navbar-container">
          <Link
            to="/"
            className="navbar-logo"
            onClick={() => {
              closeMobileMenu();
              scrollToTop();
            }}
          >
            <img src={hyreWorksLogo} alt="" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            {click ? (
              <MdOutlineClose size="27px" />
            ) : (
              <HiMenuAlt2 style={{ transform: "rotate(180deg)" }} size="27px" />
            )}
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToAboutUs();
                }}
              >
                About us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToService();
                }}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToPricing();
                }}
              >
                Pricing
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-links"
                onClick={() => {
                  closeMobileMenu();
                  scrollToContactForm();
                }}
              >
                Contact
              </Link>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-links"
                href="https://my.hyreworks.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Login
              </a>
            </li> */}

            <li>
              <Link
                className="nav-links-mobile"
                onClick={() => {
                  closeMobileMenu();
                  setShowFloatForm((prev) => !prev);
                  setFloatFormData("Let's Connect with our Sales Team");
                }}
              >
                Login
              </Link>
            </li>
          </ul>
          {button && (
            <button
              className="nav-getStarted-button"
              style={{ boxShadow: navbar && "none" }}
              onClick={() => {
                setShowFloatForm((prev) => !prev);
                setFloatFormData("Let's Connect with our Sales Team");
              }}
            >
              <Link> Login</Link>
            </button>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
