import React, { useState, useEffect } from "react";
import axios from "axios";
import dialerIcon from "../../assets/images/homePage/dialerIcon.png";
import mapIcon from "../../assets/images/homePage/mapIcon.png";
import mailIcon from "../../assets/images/homePage/mailIcon.png";
import fbIcon from "../../assets/images/homePage/fb.png";
import whatsappIcon from "../../assets/images/homePage/whatsapp.png";
import instaIcon from "../../assets/images/homePage/insta.png";
import pinterestIcon from "../../assets/images/homePage/pinterest.png";
import shareIcon from "../../assets/images/homePage/shareIcon.svg";
import "./ContactForm.css";

const ContactForm = () => {
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    messages: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [canWeSubmit, setCanWeSubmit] = useState(false);

  const formSubmissionUrl = "https://crm.zoho.com/crm/WebToLeadForm";

  const onChangeHandler = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });

    //clearing respective error message when user starts typing
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({
        ...formErrors,
        [e.target.name]: "",
      });
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    setFormErrors(validate(data));
    setCanWeSubmit(true);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && canWeSubmit) {
      let validFirstName = data.firstName.trim();
      let validLastName = data.lastName.trim();
      let finalData = {
        ...data,
        firstName: validFirstName,
        lastName: validLastName,
      };
      console.log("finalFormData", finalData);
      handleFormSubmit(finalData);
    }
  }, [formErrors]);

  const handleFormSubmit = async (finalData) => {
    try {
      const response = await axios.post(formSubmissionUrl, finalData);

      if (response.status === 200) {
        // Handle successful submission
        alert("Form submitted successfully");
      } else {
        // Handle error response
        console.error("Error submitting form:", response.statusText);
      }
    } catch (error) {
      // Handle network errors
      console.error("Error submitting form:", error);
    }
  };

  // function for validating all input fields
  const validate = (data) => {
    let errors = {};

    const emailRegex =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    const nameRegex = /^[a-zA-Z ]{2,30}$/;
    const phonePattern = /^[0-9]{10}$/;

    if (!data.firstName) errors.firstName = "First Name field cannot be empty";
    else if (!nameRegex.test(data.firstName))
      errors.firstName = "Invalid First Name";
    else if (data.firstName.length < 2)
      errors.firstName = "First Name cannot be less than 2 characters";
    else if (data.firstName.length > 100)
      errors.firstName = "First Name cannot be greater than 100 characters";

    if (!data.lastName) errors.lastName = "Last Name field cannot be empty";
    else if (!nameRegex.test(data.lastName))
      errors.lastName = "Invalid Last Name";
    else if (data.lastName.length < 2)
      errors.lastName = "Last Name cannot be less than 2 characters";
    else if (data.lastName.length > 100)
      errors.lastName = "Last Name cannot be greater than 100 characters";

    if (!data.email) errors.email = "Email field cannot be empty";
    else if (!emailRegex.test(data.email)) errors.email = "Email is not valid";

    if (!data.phone.match(phonePattern)) {
      errors.phone = "Please enter a valid phone number.";
    }

    if (!data.messages) errors.messages = "Message field cannot be empty";

    return errors;
  };

  return (
    <div
      className="contact-wrapper"
      id="contactFormRef"
      style={{ scrollMargin: "150px" }}
    >
      <div className="contact-text-and-form-container">
        <div className="contact-text-allinfo-container">
          <div className="contact-text-heading-desc-container">
            <p>
              Become a <span className="primary-color-text">learner</span>{" "}
              today!
            </p>
            <p>Join one of the world’s largest online learning marketplaces.</p>
          </div>
          <p>Contact Info :</p>
          <div className="contact-text-info-list">
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img
                  src={dialerIcon}
                  className="contact-icon"
                  alt="dialer-icon"
                />
              </div>
              <div className="single-contact-info-detail">+91-172-613-1700</div>
            </div>
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img src={mailIcon} className="contact-icon" alt="email-icon" />
              </div>
              <div className="single-contact-info-detail">
                info@digimantra.com
              </div>
            </div>
            <div className="single-contact-info">
              <div className="icon-wrapper">
                <div className="single-contact-info-list-style" />
                <img src={mapIcon} className="contact-icon" alt="map-icon" />
              </div>
              <div className="single-contact-info-detail">
                Plot No. C-212, Ground Floor, Phase 8-B, Industrial Area,Sec 74,
                Sahibzada Ajit Singh Nagar, Punjab 160055
              </div>
            </div>
          </div>
          {/* <div className="contact-icons">
            <img src={fbIcon} className="socialmedia-icon" alt="fbIcon" />
            <img src={instaIcon} className="socialmedia-icon" alt="instaIcon" />
            <img
              src={whatsappIcon}
              className="socialmedia-icon"
              alt="whatsappIcon"
            />
            <img
              src={pinterestIcon}
              className="socialmedia-icon"
              alt="pinterestIcon"
            />
          </div> */}
        </div>
        {/* *********************** */}
        <form
          id="webform"
          name="WebToLeads5267894000001348003"
          onSubmit={onSubmitHandler}
          acceptCharset="UTF-8"
          className="contactformCSS"
          autoComplete="off"
        >
          <div className="input-container">
            <div className="input-wrapper-div">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First name "
                value={data.firstName}
                onChange={onChangeHandler}
              />
              {Object.keys(formErrors).length > 0 && formErrors.firstName ? (
                <span className="contactform-errorField">
                  {formErrors.firstName}
                </span>
              ) : null}
            </div>
            <div className="input-wrapper-div">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last name "
                value={data.lastName}
                onChange={onChangeHandler}
              />
              {Object.keys(formErrors).length > 0 && formErrors.lastName ? (
                <span className="contactform-errorField">
                  {formErrors.lastName}
                </span>
              ) : null}
            </div>
          </div>
          <label htmlFor="email">Email Address</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Input your email address in here"
            value={data.email}
            onChange={onChangeHandler}
          />
          {/* {Object.keys(formErrors).length > 0 && formErrors.email ? (
            <span className="contactform-errorField">{formErrors.email}</span>
          ) : null}
          <label htmlFor="email">Phone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            placeholder="9999999999"
            value={data.phone}
            onChange={onChangeHandler}
          /> */}
          {Object.keys(formErrors).length > 0 && formErrors.email ? (
            <span className="contactform-errorField">{formErrors.email}</span>
          ) : null}
          <label htmlFor="messages">Messages</label>
          <textarea
            name="messages"
            id="messages"
            placeholder="Enter your message here"
            value={data.messages}
            onChange={onChangeHandler}
          />
          {Object.keys(formErrors).length > 0 && formErrors.messages ? (
            <span className="contactform-errorField">
              {formErrors.messages}
            </span>
          ) : null}
          <button
            type="submit"
            id="formsubmit"
            className="contactform-submitBtn"
          >
            <span>Register Now</span>
            <img className="share-icon" src={shareIcon} alt="share-icon" />
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
