import icon1 from "../../../../assets/images/homePage/ServiceCarouselImage1.png"
import icon2 from "../../../../assets/images/homePage/ServiceCarouselImage2.png"
import icon3 from "../../../../assets/images/homePage/ServiceCarouselImage3.png"
import icon4 from "../../../../assets/images/homePage/ServiceCarouselImage4.png"





export const ServiceCarouselData = [
    {
        key:1,
        icon: icon1,
        text: "Finance and Banking",
    },
    {
        key:2,
        icon: icon2,
        text: "Healthcare and Pharmaceuticals",
    },
    {
        key:3,
        icon: icon3,
        text: "E-commerce and Retail",
    },
    {
        key:4,
        icon: icon4,
        text: "Internet of Things (IoT)",
    },
    {
        key:5,
        icon: icon1,
        text: "Internet of Things (IoT)",
    },
    {
        key:6,
        icon: icon2,
        text: "Internet of Things (IoT)",
    },
]