import React from "react";
import "./css/letUsTalkCard.css";
import shareIcon from "../../assets/images/homePage/shareIconColor.svg";
const LetUsTalkCard = () => {
  return (
    <>
      <div className="talkCard-container">
        <div className="talkCard-main">
          <div className="talCard-text">
            <div className="text-up">
              Unlock your full potential with DML Learning!
            </div>
            <div className="text-down"> Your path to success starts here</div>
          </div>
          <button className="talkCard-btn">
            <span>START LEARNING</span>
            <img className="share-icon" src={shareIcon} alt="share-icon" />
          </button>
        </div>
      </div>
    </>
  );
};

export default LetUsTalkCard;
