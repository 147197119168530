import React from "react";
import "./HeroSection.css";
// import { FiChevronRight } from "react-icons/fi";
import shareIcon from "../../../../assets/images/homePage/shareIcon.svg";
import bannerImg from "../../../../assets/images/homePage/heroSectionImg.png";

const HeroSection = ({ setShowFloatForm, setFloatFormData }) => {
  return (
    <div className="hero-section-container" id="topOfPageRef">
      <div className="hero-content-wrapper">
        <div className="hero-content-container">
          <div className="hero-subtitle-wrapper">
            <div className="hero-heading-subtitle">
              CONNECTING PEOPLE WITH KNOWLEDGE
            </div>
            <div className="hero-subtitle-dash"></div>
          </div>
          <div className="hero-heading">
            <span className="hero-heading-title">
              Explore.<span className="primary-color-text"> Learn.</span>{" "}
              Thrive.
            </span>
          </div>
          <div className="hero-description">
            From the most-in demand skills to complex tech topics, DML Learning
            supports you at every stage of your career. We take pride in our
            successful data-driven, high quality, in demand tech learning
            programs taught by industry experts.
          </div>
          <button
            className="hero-button"
            onClick={() => {
              setShowFloatForm((prev) => !prev);
              setFloatFormData("Let's Connect with our Design Representative");
            }}
          >
            <span>START LEARNING</span>
            <img className="share-icon" src={shareIcon} alt="share-icon" />
          </button>
        </div>
      </div>
      <div className="hero-image-wrapper">
        <div className="hero-image-container">
          <img className="hero-image" src={bannerImg} alt="hero-image" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
