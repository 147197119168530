import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./Aboutus.css";
// import Slider from "react-slick";
// import { AboutusCarouselSettings } from "../../../../utils/AboutusCarouselSettings";
import aboutusImg1 from "../../../../assets/images/homePage/aboutusImg1.png";
import aboutusImg2 from "../../../../assets/images/homePage/aboutusImg2.png";
import aboutusImg3 from "../../../../assets/images/homePage/aboutusImg3.png";
SwiperCore.use([Navigation]);
// import InfoHeader from "../../../../components/info-header-reusable/InfoHeader";
const aboutCarouselData = [
  {
    image: aboutusImg2,
  },
  {
    image: aboutusImg1,
  },
  {
    image: aboutusImg3,
  },
  {
    image: aboutusImg1,
  },
  {
    image: aboutusImg2,
  },
  {
    image: aboutusImg1,
  },
];

const headingData = {
  title: "About Us",
  description: (
    <>
      <p className="mb-2">
        DigiMantra Learning empowers every individual to innovate, solve
        complex challenges, and make a lasting impact in their career and
        beyond. As the official learning platform for all the latest
        technologies, we provide world-class educational resources to all
        learners.
      </p>
      <p className="mb-2">
        Through a blend of interactive tutorials, hands-on learning, and
        role-based learning paths, we equip learners of all backgrounds with
        the knowledge and skills needed to thrive in the digital age.
      </p>
    </>
  ),
};

const Aboutus = () => {
  // const [progressVal, setProgressVal] = useState(1.5);
  // const swiperRef = useRef();

  // const sliderChangeCallback = (currentSlide, nextSlide) => {
  //   let calc = (nextSlide / (aboutCarouselData.length - 1)) * 100;
  //   setProgressVal(calc);
  // };

  return (
    <div className="aboutus-container" id="aboutUsRef">
      <div className="aboutUs-heading">
        <div className="aboutUs-title">
          <div className="aboutUs-title-dash"></div>
          <div className="aboutUs-title-text">{headingData.title}</div>
        </div>
        <div className="aboutUs-heading-and-desc">
          <div className="aboutUs-heading-text">
            Achieve your next{" "}
            <span className="primary-color-text">career goal</span> with
            confidence
          </div>
          <div className="aboutUs-heading-desc">{headingData.description}</div>
        </div>
      </div>
      {/* *********************************************************/}
      <div className="aboutus-carousel-outer-div">
        {/* <Slider
          beforeChange={sliderChangeCallback}
          className="aboutus-slider-styling"
          {...AboutusCarouselSettings}
        > */}
        <Swiper
          loop={true}
          slidesPerView={3}
          spaceBetween={30}
          autoplay={{ delay: 200, pauseOnMouseEnter: true }}
          // onBeforeInit={(swiper) => {
          //   swiperRef.current = swiper;
          // }}
          // className="slider-container"
        >
          {aboutCarouselData.map((data, idx) => {
            return (
              <SwiperSlide className="aboutus-carousel-all-content" key={idx}>
                <img
                  src={data.image}
                  className="aboutus-img"
                  alt="aboutus carousel visuals"
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
        {/* </Slider> */}
        {/* progress bar of slider */}
        <div className="aboutus-slider-progressbar">
          <div
            className="aboutus-slider-progressDot"
            // style={{ left: progressVal + "%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
