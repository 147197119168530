import React from "react";
import "./WhatWeHave.css";

const WhatWeHave = () => {
  const dataContent = [
    {
      number: "01",
      title: "Personalized Courses",

      description:
        "Access cutting-edge tech courses led by industry experts. Our platform is designed to cater to your unique objectives, providing tailored learning paths and specialized guides for rapid professional growth.",
    },
    {
      number: "02",
      title: "Hands-on-Learning",

      description:
        "Immerse yourself in hands-on learning to enhance your skills. Utilize sandbox environments, engage in challenge-driven courses, and download source code for practice sessions to solidify your understanding.",
    },
    {
      number: "03",
      title: "Skill Validation",
      description:
        "Take tech assessments to earn professional certifications. Whether you're seeking academic credits or Continuing Education Units (CEUs), we offer various pathways to demonstrate your proficiency and progress.",
    },
  ];
  return (
    <div className="wwh-cards-container">
      {dataContent.map((data, idx) => {
        return (
          <div className="wwh-single-card" key={idx}>
            <div className="wwh-number-and-title">
              <div className="wwh-number">{data.number}</div>
              <div className="wwh-title">{data.title}</div>
              <div className="wwh-desc">
                {data.description.substring(0, 215)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WhatWeHave;
