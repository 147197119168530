import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import { blogData } from "./blogData";

import { getBlogs } from "../../redux/slices/blogSlice";
import bannerImg from "../../assets/images/homePage/artificial-intelligence.png";
import "./BlogPage.css";
const headingData = {
  title: "Blogs",
  heading: "How to Leverage AI for Career Growth: Your Guide to Success",
  description:
    "As you navigate the ever-evolving job market, it's crucial to stay ahead of the curve and leverage the power of AI to propel your career forward. In this personalized guide, we'll explore how you can harness the power of artificial intelligence to unlock new opportunities and achieve your professional goals.",
};

function BlogPage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("id", id);
  const { blog } = useSelector((state) => ({
    blog: state?.blogReducer?.blog,
  }));

  useEffect(() => {
    dispatch(getBlogs(id));
  }, [dispatch, id]);

  return (
    <>
      <div className="specific-blog-container" id="blogRef">
        <div className="blog-banner-image">
          <img alt="banner" src={blog?.imageUrl} width="1920" height="573" />
        </div>
        <div className="specific-content-container">
          <h2 className="specific-content-title">{blog?.title}</h2>
          {/* <div className="specific-content-descriptions">
            <p>{blog?.smallDescription}</p>
          </div> */}
        </div>
        <div className="specific-new-topic-container">
          <div dangerouslySetInnerHTML={{ __html: blog?.description }} />
          {/* {ReactDOM.createPortal(
          )} */}
          {/* {blogData.map((data, index) => (
            <>
              <h4 className="specific-new-topic-title">{data.title}</h4>
              <div className="specific-new-topic-description">
                {data.description}
              </div>
            </>
          ))} */}
        </div>

        {/* <div className="specific-new-topic-container">
          <h4>CONCLUSION</h4>
          <div className="specific-new-topic-head">
            Crafting effective microcopy is a crucial part of UX writing, and it
          </div>
        </div> */}
        <div className="talkCard-main">
          <div className="talCard-text">
            <div className="text-up">
              Finding a company that understands your digital needs is hard.
            </div>
            <div className="text-down">
              Let’s create an impact on your business.
            </div>
          </div>
          <button className="talkCard-btn">Get a Quote</button>
        </div>
      </div>
    </>
  );
}

export default BlogPage;
