import React from "react";
import { HiChevronRight } from "react-icons/hi";
import "./service.css";
import { cardData } from "./ServiceCardData";
const Service = () => {
  return (
    <>
      <div className="service-main" id="serviceRef">
        <div className="service-heading">
          <div className="service-title">
            <div className="service-title-dash"></div>
            <div className="service-title-text">Our Courses</div>
          </div>
          <div className="service-heading-and-desc">
            <div className="service-heading-text">
              Build <span className="primary-color-text">skills</span> that open
              doors
              {/* <span style={{ color: "#17A7DD" }}>.</span> */}
            </div>
            <div className="service-heading-desc">
              Our tech courses have earned the trust and recognition of industry
              leaders, who consistently recommend our programs to their team of
              professionals. By developing durable skills in tandem with hard
              skills, you'll not only excel in your current role but also
              position yourself for success tomorrow and beyond.
            </div>
          </div>
        </div>

        <div className="card-and-background-wrapper">
          {/****************************************/}
          <div className="service-cards">
            {cardData.map((item, key) => {
              return (
                <div className="individual-card" key={key}>
                  <div className="hoverText card-title">{item.title}</div>
                  <div className="hoverText card-desc">{item.desc}</div>
                  {/* <div className="learnMore-and-arrow">
                    <div className="hoverText card-learn-more">Learn More</div>
                    <div className="card-arrow">
                      <div className="hoverText card-arrow-tail"></div>
                      <HiChevronRight
                        className="hoverText card-arrow-head"
                      />
                    </div>
                  </div> */}
                </div>
              );
            })}
          </div>

          {/****************************************/}
          <div className="service-background-div"> </div>
        </div>
      </div>
    </>
  );
};

export default Service;
